<template>
  <div></div>
</template>
<script>
  export default {
    components: {
    },
    props: {
    },
    data() {
      return {
        state: 0
      }
    },
    inject: {
      publicData: {
        default: {}
      },
      getFunc: {
      }
    },
    watch: {
    },
    computed: {
      pano() {
        return this.publicData.pano || {}
      },
    },
    created() {
    },
    mounted() {
      this.$emit('ready', {
        vm: this,
        events: {
          panoIn: {
            name: 'renewSence',
            arg: 'itemIn'
          },
          inComplete: {
            name: 'renewSence',
            arg: 'default'
          }
        },
        funcs: {
          nextView: this.nextView,
        }
      })
      this.nextView()
    },
    destroyed() {
    },
    methods: {
      setIntro(intro) {
        this.getFunc('changePublicData')({ intro })
      },
      nextView() {
        console.log('next', this.state)
        if (this.hasPanoIn() && this.state < 1) {
          this.setIntro(this.pano)
          this.$emit('panoIn')
          this.state = 1;
        } else {
          this.$emit('inComplete')
          this.state = 4;
        }
      },
      hasPanoIn() {
        var pano = this.pano
        console.log(pano)
        if (pano.description || pano.introImage) {
          return true
        }
      },
    },
  }
</script>
<style scoped>
  .panoTitle-enter-active {
    animation: panoTitle-in .5s;
  }

  .panoTitle-leave-active {
    animation: panoTitle-in .5s reverse;
  }

  @keyframes panoTitle-in {
    0% {
      transform: scale(0);
    }

    50% {
      transform: scale(1.5);
    }

    100% {
      transform: scale(1);
    }
  }

  .panoTitle {
    pointer-events: none !important;
    background-image: linear-gradient(to bottom, #0005,#0002, #0000);
    text-shadow: 1px 1px 2px black;
    color: white
  }

    .panoTitle h1 {
      margin: 10px 0;
      font-size: 1.4rem;
    }
</style>
